/**
 *
 * Enables different scripts to update the url without destroying the changes done by the others
 * this script is only going to work with this versions of uriComponentsBase
 *     '?'
 *     '#'
 *     '#?'
 *     '#!'
 *
 * Needs work to enable '/' version and any other instance were any of the
 * uriComponents options are not different from each other
 *
 * @author David Pocina <dpocina[at]zerogrey[dot]com>
 *
 */

(function ( $, _ ) { /* global History, _, DEBUG */
	'use strict';

    // Establish the root object ('window' in the browser)
    var root = window;

	// url for the AJAX request
	var REQUEST_URL = root.makeUrl( { module: 'eshop', manager: 'eshop', action: 'getCategoryAliasWithFilter' } );

	var ROOT_URL = root.SGL_JS_WEBROOT + root.SGL_JS_CURRURL;

	var heading_category = $( '[data-zg-role="category-heading"]' ).first();

	var description_category = $( '[data-zg-role="category-description"]' ).first();

	// URIMGR CLASS DEFINITION
	// =======================

	/**
	 *
	 * @param {Object} options
	 * @constructor
	 */
	var UriMgr = function ( options ) {
		this.options = options;

		this.base = null;
		this.hash = null;
		this.status = null;
		this.components = {};

		this.setEventHandlers();

		this.updateStatus();
		if( !this.hash.includes( "access_token" ) ){
			this.components = this.getComponentsFromUri();
			this.updateTab();
		}

		this.getCategoryAliasWithFIlter( this.getStatus() );
		this.alias = null;
	};

	UriMgr.DEFAULTS = {
		uriComponentsBase: '?',
		uriComponentsAssign: '=',
		uriComponentsMultiple: ',',
		uriComponentsJoin: '&',

		initialUriComponents: null
	};


	/**
	 * Remove all components.
	 *
	 * @param {?Object=} info
	 */
	UriMgr.prototype.empty = function ( info ) {
		var data;

		// remove current components
		this.components = {};

		if ( info.title ) {
			data = { title: info.title };
		} else {
			data = {};
		}

		this.push( data );
	};


	/**
	 * get the components from an url.
	 *
	 * @param {string=} uri
	 *
	 * @returns {Object}
	 */
	UriMgr.prototype.getComponentsFromUri = function ( uri ) {
		var temp = ( uri || document.location.href ).split( this.options.uriComponentsBase )[1],
			result = {},
			components = [],
			i;

		if ( temp ) {
			temp = decodeURIComponent( temp );

			// remove the hash
			if ( temp.length && temp.indexOf( '#' ) !== -1 ) {
				temp = temp.split( '#' )[0];
			}

			// divide the components into an array
			if ( temp.length ) {
				components = temp.split( this.options.uriComponentsJoin );
			}

			// create an array containing the components
			for ( i = 0; i < components.length; i++ ) {
				var component = components[i].split( this.options.uriComponentsAssign );

				if (
					component &&
					component[0] && component[0].length &&
					component[1] && component[1].length
				) {
					result[component[0]] = component[1].split( this.options.uriComponentsMultiple );
				}
			}
		}



		return result;
	};


	/**
	 * Returns the current Url information
	 *
	 */
	UriMgr.prototype.getStatus = function () {
		return {
			'base': this.base,
			'components': _.extend( {}, this.options.initialUriComponents || {}, this.components || {} ),
			'hash': this.hash,
			'index': History.getCurrentIndex(),
			'status': this.status
		};
	};


	/**
	 * Generates and returns a new url based on the components
	 *
	 * @param {Object} info
	 */
	UriMgr.prototype.getUrl = function ( info ) {
		var uri = info.url.split( this.options.uriComponentsBase )[0],
			components = $.extend( {}, this.getComponentsFromUri( info.url ) || {}, info.applied || {} );

		return this.processUriWithComponents( uri, components );
	};


	/**
	 *
	 */
	UriMgr.prototype.load = function ( info ) {
		if ( History.enabled ) {
			this.push( info );
		} else {
			document.location.assign( info.url );
		}
	};


	/**
	 *
	 * @param {string=} uri
	 * @param {Object=} components
	 *
	 * @returns {string}
	 */
	UriMgr.prototype.processUriWithComponents = function ( uri, components ) {
		var items = [],
			value,
			index;

		uri = uri || this.base;
		components = components || this.components;

		for ( index in components ) {
			if ( components.hasOwnProperty( index ) ) {
				if ( _.isArray( components[index] ) ) {
					value = components[index].sort().join( this.options.uriComponentsMultiple );
				} else {
					value = components[index];
				}

				items.push(
					'' +
					index +
					this.options.uriComponentsAssign +
					encodeURIComponent( value )
				);
			}
		}

		if ( items.length ) {
			if ( uri.slice( -1 ) !== this.options.uriComponentsBase ) {
				uri += '' + this.options.uriComponentsBase + items.join( this.options.uriComponentsJoin );
			}
		}

		return uri;
	};


	/**
	 *
	 */
	UriMgr.prototype.push = function ( info, replace ) {
		var url, action, isUpdated;

		if ( History.enabled ) {

			isUpdated = this.updateComponents( info );

			url = this.processUriWithComponents( info.url );

			if ( isUpdated || url !== this.base ) {
				action = (replace ? 'replaceState' : 'pushState');

				History[action](
					$.extend( {}, info.data || {}, info.applied || {} ),
					info.title || document.title,
					url
				);
			}

		} else {

			this.updateTab( document.location.hash.replace( /^#/, '' ) );

		}

		var	customAlias = this.getCustomAlias( url );
		var canonical = root.URL;
		var seoTitle = root.SEO_TITLE;
		var heading_value = root.CATEGORY_HEADING;
		var description_value = root.CATEGORY_DESCRIPTION;


		if( customAlias !== false ){

			var customAliasURL = root.SGL_JS_WEBROOT + '/' + customAlias.name;

			if ( customAlias.metatags.h1 !== undefined ) {
				heading_value = customAlias.metatags.h1;
			}
			
			/*if ( customAlias.metatags.h2 !== undefined ) {
				description_value = customAlias.metatags.h2;
			}*/	

			if ( customAlias.metatags.description !== undefined ) {
				description_value = customAlias.metatags.description;
			}

			if ( customAlias.metatags.title !== undefined ) {
				seoTitle = customAlias.metatags.title;
			}


			history.replaceState(null, '', customAliasURL);
			canonical = customAliasURL;

		}

		// Ajax call returns an empty Array if we have no results and Object if we have results
		if( heading_category.length > 0 && this.alias.length === undefined && heading_value !== undefined ){
			heading_category.text( heading_value );
		}

		if( description_category.length > 0 && this.alias.length === undefined && description_value !== undefined ){
			description_category.text( _.unescape( description_value ) );
		} 
		if(heading_value !== ''){
			document.querySelector('link[rel="canonical"]').setAttribute("href", canonical);
		}
		document.title = seoTitle;

	};


	/**
	 *
	 */
	UriMgr.prototype.removeHash = function ( hash ) {
		if ( History.enabled ) {
			History.replaceState(
				{
					tab: hash,
					time: new Date().getTime()
				},
				document.title,
				document.location.href.replace( '#' + hash, '' )
			);
		}
	};

	/**
	 *
	 */
	UriMgr.prototype.setEventHandlers = function () {
		var that = this;

		$( window ).on( 'statechange.urimgr', function () {
			var status;

			that.updateStatus();
			that.components = that.getComponentsFromUri();

			status = that.getStatus();

			$( document ).trigger(
				'zg.urimgr.updatedUri',
				[status]
			);

			if ( DEBUG ) {
				console.log( 'URIMGR', status );
			}
		} );

		$( window ).on( 'hashchange.urimgr', function () {
			var hash = History.getHash();
			that.hash = History.isTraditionalAnchor( hash ) ? hash : null;

			that.updateTab();
		} );
	};

	/**
	 * Get category Alias 
	 */

	UriMgr.prototype.getCategoryAliasWithFIlter = function ( status ) {

        $.ajax({
			url:  REQUEST_URL,
            type: 'POST',
            context: this,
            data: {categoryId: status.components.frmCatID},
            cache: false,
            success: function ( response ) {
            	//console.log('response category alias filter', response );
				this.alias = response;
			},
			error: (function ( response ) {
				console.log ('Error With Category alias filter');
			}).bind( this )
        });

	};

	/**
	 * Set URl based on Alias from SEO CMS Options 
	 */

	UriMgr.prototype.getCustomAlias = function( url ) {
		var customAlias = false;
		for ( const element in this.alias ) {
			console.log(this.alias[element].queryString);
			var regex_escaped = this.alias[element].queryString.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
			var regex = new RegExp( regex_escaped +'$' );
				if(regex.test(url) ) {
				console.log('yes');
				customAlias = this.alias[element];
			}
		}
		return customAlias;
	};


	/**
	 * Update the components
	 *
	 * @param {object} info
	 */
	UriMgr.prototype.updateComponents = function ( info ) {
		var components, i, isUpdated, property, value;

		// allow to send 'available' as a string instead of an array
		if ( _.isString( info.available ) ) {
			info.available = info.available.split();
		}

		if ( info && info.available && !_.isEmpty( info.available ) ) {

			components = _.extend( {}, this.options.initialUriComponents || {}, this.components || {} );

			// We go through the available array and add / replace / remove as
			// necessary from this.components
			if ( _.isArray( info.available ) ) {
				for ( i = 0; i < info.available.length; i++ ) {
					property = info.available[i];
					value = ( info.applied || {} )[property];

					// isEmpty fails on numbers
					if ( _.isNumber( value ) ) {
						value = '' + value;
					}

					if (
						components[property] != value &&
						!_.isEqual( components[property], value )
					) {
						isUpdated = true;
					}

					if (
						_.isUndefined( value ) ||
						_.isEmpty( value ) // null, empty string, empty array
					) {
						delete this.components[property];
						delete this.options.initialUriComponents[property];
					} else {
						this.components[property] = value;
					}
				}
			}
		} else {
			// we don't know which components should be updated, which are to be
			// removed and which are to be kept.
			// We go for the nuclear option and just merge the current
			// components with the ones we just received.
			this.components = _.extendOwn( this.components, (info.applied || {}) );

			for ( property in info.applied ) {
				if (
					info.applied.hasOwnProperty( property ) &&
					this.components[property] != info.applied[property] &&
					!_.isEqual( this.components[property], info.applied[property] )
				) {
					isUpdated = true;
				}
			}
		}

		return isUpdated;
	};


	/**
	 * get current Uri
	 *
	 */
	UriMgr.prototype.updateStatus = function () {
		var hash = History.getHash();
		this.hash = History.isTraditionalAnchor( hash ) ? hash : null;

		this.status = History.getState();
		this.base = this.status.hashedUrl.split( this.options.uriComponentsBase )[0];
	};


	/**
	 * open tab / collapsible
	 *
	 */
	UriMgr.prototype.updateTab = function ( hash ) {
		hash = hash || this.hash;

		function openTab ( hash ) {
			if ( hash ) {
				$( '[data-toggle="tab"][href="#' + hash + '"]' ).tab( 'show' );
			}
		}

		function openCollapsable ( hash ) {
			if ( hash ) {
				$( '#' + hash + '.collapse' ).collapse( 'show' );
			}
		}

		// open tab / collapsible
		if ( hash && History.isTraditionalAnchor( hash ) ) {
			var $tab = $( '#' + hash );

			if ( $tab.length ) {
				openTab( hash );
				openCollapsable( hash );

				// in case is a tab inside a tab or collapsable (help section)
				openTab( $tab.closest( '.tab-pane' ).attr( 'id' ) );
				openCollapsable( $tab.closest( '.collapse' ).attr( 'id' ) );
			}

			// remove the hash from the url
			this.removeHash( hash );
		}
	};


	// URIMGR PLUGIN DEFINITION
	// ========================

	/**
	 *
	 * @param info
	 * @returns {*}
	 */
	function Plugin ( info ) {
		var $window, data, result;

		// we need the history plugin for this to work
		if ( window.History ) {

			$window = $( window );
			data = $window.data( 'zg.urimgr' );

			if ( !data ) {
				var options = $.extend( {}, UriMgr.DEFAULTS, window.ZG_CONFIG || {} );
				$window.data( 'zg.urimgr', (data = new UriMgr( options )) );
			}

			if ( info ) {
				switch ( info.action ) {
					case 'empty':
						// Remove all components
						result = data.empty( info );
						break;
					case 'getUrl':
						// Generates and returns a new url based on the components
						result = data.getUrl( info );
						break;
					case 'getStatus':
						// Returns the current Url information
						result = data.getStatus( info );
						break;
					case 'load':
						// If the browser supports history push the url, otherwise redirect to the url
						result = data.load( info );
						break;
					case 'replace':
						// Replace the current history item with the new components
						result = data.push( info, true );
						break;
					default:
						// Push this url components to the history
						result = data.push( info );
						break;
				}
			}

			return result;

		} else {

			throw new Error( 'History Plugin is not available' );

		}
	}

	$.uriMgr = Plugin;
	$.uriMgr.Constructor = UriMgr;

	$( function () {
		Plugin.call();
	} );

}( jQuery, _ ));