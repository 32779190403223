/**
 * Used in order manager when $result->accept && $result->order.status_id == '1', checking with ajax call if the status of the order change
 * from try to pay into paid. If change reload the page, if not change show messagge "try to pay"
 *
 * @author: Pietro Vignola <pvignola[at]kooomo[dot]com>
 *
 */

(function ($) {

    "use strict";

    /**
     * @selector data-zg-role="zg-thank-you-page" The plugin start if there is the selector in the dom when the page load
     */
    var toggle = '[data-zg-role="zg-thank-you-page"]';

    var ZG_ThankYouPage = function (element, config) {
        var self = this;
        this.init = function (element, config) {
            var $element = $(element);
            self.$eventBus = $(document);

            self.$element = $element;
            self.order_id = self.$element.data('orderid');
            self.msg      = self.$element.find('[data-zg-role="msg-thank-you-page"]');
            
            window.localStorage.setItem('state_payment','trying');
            window.localStorage.setItem('is_payment',self.order_id);

            paymentCheck();
            //self.tryingToPay();
        };

        // Ajax call to check order status
        this.tryingToPay = function () {

            var i = 0,
                intervalTime = 3000,  // 3 seconds
                limit = 5;						// 5 times - 15 seconds

            var looper = setInterval(

                function() {

                    var request = {
                            orderId: self.order_id
                        },
                        url = window.makeUrl({
                            module: 'eshop',
                            action: 'getStatusOrder'
                        });

                    $.getJSON(url, request, function (response) {

                        if( response.status_id != 1 ){ // 1 is Trying To Pay
                            // Reload current page
                            location.reload();
                        }

                    });

                    if (i >= limit) {
                        // * Stop checking *
                        clearInterval(looper);
                        // - Remove message
                        self.$element.removeClass('well');
                        // - Remove class loading
                        self.$element.find( '.loading' ).removeClass('loading');
                        // - Show message for Trying To Pay
                        self.msg.remove();
                    }

                    i++;
                },

                intervalTime);

        };

        this.init(element, config);
    };

    $.fn.zg_thank_you_page = function (config) {
        return this.each(function () {
            var $this = $(this);
            var data = $this.data('zgThankYouPage');
            if (!data) {
                $this.data('zgThankYouPage', (data = new ZG_ThankYouPage(this, config)));
            }
        });
    };

    $(function () {
        $(toggle).zg_thank_you_page(window.ZG_CONFIG);
    });

})(jQuery);