/**
 * 2002-km-cookies-policy.js
 *
 * Cookies policy plugin
 *
 * @author: Dario Lopez <dlopez[at]kooomo.com>
 *
 */


(function ( $, _ ) {
	'use strict';

	// Establish the root object ('window' in the browser)
	var root = this;

	// We are doing this just to keep compatibility with the former cookie policy logic
	var CONTAINER_ID = 'content_cookie_policy_gdpr';

	/**
	 * @selector #content_cookie_policy_gdpr The plugin start if there is the selector in the dom when the page load
	 */
	var SELECTOR = '#' + CONTAINER_ID;

	// SCRIPT OPTIONS

	/**
	 * @param {string}  [filterContainer] Container of the filters html
	 * @param {boolean} [forceFilters] Initialize filter plugin even if we didn't request filters. To be used with front end filtering
	 * @param {string}  [initialFilters]
	 */
	var DEFAULTS = {
		cookieName: 'KM-cookies-policy-' + window.SGL_JS_THEME,
		cookieFileName: 'cookie_policy_gdpr.html',
		allowCookies: '[data-zg-role="allow-cookies"]'
	};


	// COOKIES-POLICY CLASS DEFINITION
	// ===========================

	/**
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var CookiesPolicy = function ( element, options ) {
		this.$element = $( element );
		this.options = _.clone( DEFAULTS );
		this.updateOptions( options );

		this.$cookieModal = null;
		this.$cookiesSelected = null;
		this.selectedCookieCategories = null;

		this.__setEventHandlers();
		this.init();
	};

	CookiesPolicy.prototype.init = function () {
		var that = this;
		this.selectedCookieCategories = root.zgReadCookie( this.options.cookieName );

		// Looping into the full cookie list in order to unblock all the mandatory cookies
		this.options.cookieList.forEach( function(cookie){
			// If it is mandatory and if it has any blocked domain added
			if( cookie.mandatory && cookie.blocked_domains[0].length > 0 ){
				if( window.yett ) {
					// then we unblock those domains
					cookie.blocked_domains.forEach( function (blocked_domain){
						window.yett.unblock( new RegExp(blocked_domain, "i") );
					});
				}
			}
		});

		// CHECK IF THERE IS COOKIE
        if ( !this.selectedCookieCategories ) {
        	root.loadAjaxFile( this.options.cookieFileName, CONTAINER_ID);

            $( document ).on( 'zg.loadAjaxFile.success', function (e, fileName) {
                if ( that.options.cookieFileName === fileName ) {
					that.$element.removeClass( 'd-none' );
                }
            });
		}
        else {
        	this.allowCookies( this.selectedCookieCategories );
		}
	};


	/**
	 *
	 * @param options
	 */
	CookiesPolicy.prototype.updateOptions = function ( options ) {
		_.extendOwn( this.options, options || {} );
	};


	CookiesPolicy.prototype.allowCookies = function ( type ) {
		var that = this;
		this.selectedCookieCategories = type;

		switch ( type ){
			case 'necessary':
				break;

			case 'all':
				// Looping through all the cookies list and unblocking the ones this have a value assigned in the "blocked_domains" property
				this.options.cookieList.forEach( function(cookie){
					if( cookie.blocked_domains[0].length > 0 ){
						if( window.yett ) {
							cookie.blocked_domains.forEach( function (blocked_domain){
								window.yett.unblock( new RegExp(blocked_domain, "i") );
							});
						}
					}
				});
				break;

			default:
				var selectedCookiesArray = [];

				// Getting the "select cookie by category" checkbox
				this.$cookiesSelected = this.$element.find( '[data-zg-role="cookies-selected"]' );

				// If the $cookiesSelected object has elements it means the category checkboxes
				// have been selected. If not, it means the cookie has already been created
				// and we need to retrieved the previous selected value from the cookie itself
				if( this.$cookiesSelected.length > 0 ) {
					Object.keys(this.$cookiesSelected).forEach(function (cookie) {
						// Checking if the checkbox are selected and building an array with the those values
						if (that.$cookiesSelected[cookie].checked) {
							selectedCookiesArray.push(that.$cookiesSelected[cookie].dataset.value)
						}
					});

					if( selectedCookiesArray.length > 0 ) {
						this.selectedCookieCategories = selectedCookiesArray.join(',');
					}
					else {
						// We land here when the "Allow selected" button was chosen but there
						// are no categories selected, only the necessary ones
						this.selectedCookieCategories = 'necessary';
					}
				}
				else {
					if( selectedCookiesArray.length > 0 ) {
						// We land here when the selected categories are retrieved from the cookie
						// The rest of the logic will be exactly the same than the one when the user
						// check a category
						selectedCookiesArray = this.selectedCookieCategories.split(',');
					}
					else {
						// We land here when the "Allow selected" button was chosen but there
						// are no categories selected, only the necessary ones
						this.selectedCookieCategories = 'necessary';
					}
				}

				if( this.selectedCookieCategories.length > 0 ) {
					// Looping into the full cookie list
					this.options.cookieList.forEach(function (cookie) {
						var isSelectedCookieCategory = selectedCookiesArray.indexOf(cookie.category);

						// If it has any blocked domain added
						if (cookie.blocked_domains[0].length > 0) {
							// and also this current cookie belongs to the allowed categories ones
							if (window.yett && (isSelectedCookieCategory != -1)) {
								// then we unblock those domains
								cookie.blocked_domains.forEach(function (blocked_domain) {
									window.yett.unblock(new RegExp(blocked_domain, "i"));
								});
							}
						}
					});
				}
				break;
		}

	};


	/**
	 *
	 * @private
	 */
	CookiesPolicy.prototype.__setEventHandlers = function () {
		var that = this;

		$( document ).on( 'click.zg.cookies.allowCookies', this.options.allowCookies, function ( e ) {
			that.allowCookies( this.dataset.type );
			root.zgCreateCookie( that.options.cookieName, that.selectedCookieCategories, 30);
			that.$element.addClass( 'd-none' );
		} );
	};


	// COOKIES-POLICY-PLUGIN DEFINITION
	// ============================

	function Plugin ( option, updateOptions ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.CookiesPolicy' );
			var options = $.extend( {}, root.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.CookiesPolicy', (data = new CookiesPolicy( this, options )) );
			} else if ( updateOptions && typeof option === 'object' ) {
				data.updateOptions( option );
			}
		} );
	}

	$.fn.zgCookiesPolicy             = Plugin;
	$.fn.zgCookiesPolicy.Constructor = CookiesPolicy;


	// COOKIES-POLICY-PLUGIN DATA-API
	// ===================

	// default product - called on page load
	$( function () {
		$( SELECTOR ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

}.call( this, jQuery, _ ));
