// add your site specific javascript here
$( document ).ready(function() {
	'use strict';

	$.ajaxSetup( {
		cache: true
	} );

	//For crawler
	if ( !window.JS_ENABLED ) {
		$.ajax( {
			url:      window.makeUrl( { module: 'eshop', action: 'setJavascriptEnabled' } ),
			type:     'post',
			dataType: 'json'
		} );
	}

	//Return to top
	$( window ).scroll( function() {
		if ( $( this ).scrollTop() > 200) {        // If the page is scrolled more than 200px
			$( '#return-to-top' ).fadeIn( 200 );
		} else {
			$( '#return-to-top' ).fadeOut( 200 );
		}
	});
	$( '#return-to-top' ).click( function() {
		
		$( 'body,html' ).animate({
			scrollTop : 0
		}, 500);
	});

	var $searchContainer = $( '#searchContainer' );
	$( '[data-zg-role="btn-search"]' ).click( function(){
		if( $searchContainer.hasClass( 'd-none' ) ){
			$searchContainer.removeClass( 'd-none' );
		}
		else {
			$searchContainer.addClass( 'd-none' );
		}
	});


	//Increasing-decreasing qty input
	$( '[data-zg-role="change-qty"]' ).click( function(){
		event.preventDefault();
		var type = $( this ).data( 'type' );
		var $inputQtySibling = $( this ).parent().siblings( '[data-zg-role="quantity"]' );
		var maxQty = $inputQtySibling.data( 'max' );
		var floatQty = $inputQtySibling.data( 'floatQty' );
		var qty = $inputQtySibling.val();
		var step;

		if( floatQty ){
			step = 0.01;
		}
		else {
			step = 1;
		}
		qty = Number(qty);

		$( '[data-zg-role="change-qty"]' ).attr( 'disabled', false );
		if( type == "minus" ){
			//If qty is 0, we will disable the current minus icon.
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == 0 ){
				$( this ).attr( 'disabled', true );
			}
			else{
				qty = qty - step;
			}
		}
		else {
			//If qty is equal to maxQty, we will disable the current plus icon.
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == maxQty ){
				$( this ).attr( 'disabled', true );
			}
			else {
				qty = qty + step;
			}
		}

		$( this ).parent().siblings( '[data-zg-role="quantity"]' ).val( qty );
	});
	/*SIZE CONVERSION window.TABLE_SIZE */
	if(typeof window.TABLE_SIZE != undefined && typeof SIZE_INFO != 'undefined'){

		//duplicate content
		var $label_size=$('.size-standard');
		var $content_size=$('.content-size-standard');
		var $content_size_select=$('.content-size-standard-select');
		var $array_content={};
		var $array_content_select={};
		var $conversion_visible={};
		var is_first =true;
		$('#size-label').append('<option value="size">Size</option>');

		var there_is_size_convertion = false;


		for (var key in window.TABLE_SIZE) {
			for (var k in window.TABLE_SIZE[key]) {
				/*CHECK FOR SIZE INFO E RECREATE OPTION IF NEEDED*/
				
				if(k == SIZE_INFO ){
					/*console.log('size k:'+k+' SIZE_INFO:'+SIZE_INFO);
					console.log(window.TABLE_SIZE[key][k]);
					console.log(key);*/
					var $temp_size= $content_size.find('[data-option-id]').find('[data-name="'+window.TABLE_SIZE[key][k]+'"]');
					$temp_size.attr('data-name',key);
					//$temp_size.html(key);
					//window.TABLE_SIZE[key][k]
					there_is_size_convertion = true;
				}
			}
		}

		if (SIZE_INFO != '' && there_is_size_convertion == false){
			$('#size-label').hide();
			$('#size-label').next().removeClass('hidden');
		}

		$content_size=$('.content-size-standard');
		for (var key in window.TABLE_SIZE) {
			for (var k in window.TABLE_SIZE[key]) {
				//duplicate content and label
				if(is_first){
					/*LABEL*/
					var $cloned_label_size =$label_size.clone();
					$cloned_label_size.find('label').html(k);
					$cloned_label_size.removeClass('size-standard');
					$cloned_label_size.removeClass('opened');
					$cloned_label_size.addClass('size-'+k);				
					$cloned_label_size.attr('data-target',"#collapse_size_"+k);
					$cloned_label_size.insertAfter($label_size);

					

					/*OPTION*/
					//var $cloned_size_select =$('#size-label').clone();
					
					if(window.TABLE_SIZE[key][k] != ''){
						$('#size-label').append('<option value="'+k+'" >'+k+'</option>');
					}

					console.log('-'+window.TABLE_SIZE[key][k]+'-');
					/*CONTENT*/
					var $cloned_content_size =$content_size.clone();
					$cloned_content_size.find('label').html(k);
					$cloned_content_size.removeClass('content-size-standard');
					$cloned_content_size.removeClass('show');
					$cloned_content_size.addClass('content-size-'+k);
					$cloned_content_size.attr('id',"collapse_size_"+k);
					$cloned_content_size.insertAfter($content_size);
					$array_content[k]=$cloned_content_size;
					$conversion_visible[k]=false;
					/*CONTENT SELECT*/
					var $cloned_content_size_select =$content_size_select.clone();
					$cloned_content_size_select.find('label').html(k);
					$cloned_content_size_select.removeClass('content-size-standard-select');
					$cloned_content_size_select.removeClass('show');
					$cloned_content_size_select.addClass('content-size-'+k);
					$cloned_content_size_select.attr('id',"collapse_size_"+k);
					$cloned_content_size_select.insertAfter($content_size);
					$array_content_select[k]=$cloned_content_size_select;
					$conversion_visible[k]=false;
				}
				if($array_content[k].find('[data-name="'+key+'"]:not(.changed)').length != 0 && window.TABLE_SIZE[key][k] != ''){
					$array_content[k].find('[data-name="'+key+'"]').addClass('changed');
					$array_content[k].find('[data-name="'+key+'"]').last().attr('data-name',window.TABLE_SIZE[key][k]).html(window.TABLE_SIZE[key][k]);
					$conversion_visible[k]=true;
				}
				if($array_content_select[k].find('[data-name="'+key+'"]:not(.changed)').length != 0  && window.TABLE_SIZE[key][k] != ''){
					$array_content_select[k].find('[data-name="'+key+'"]').addClass('changed');
					$array_content_select[k].find('[data-name="'+key+'"]').last().attr('data-name',window.TABLE_SIZE[key][k]).html(window.TABLE_SIZE[key][k]);
					$conversion_visible[k]=true;
				}
			}
			is_first =false;
		}
		for (var k in $conversion_visible) {
			if(!$conversion_visible[k]){
				$('.size-'+k).remove();
			}
		}
		$('.size-label').click(function(){
			$('.size-label').removeClass('opened');
			$(this).addClass('opened');
		});

		// select Size
		$('#accordion_size > .content, #accordion_size select.content-size-select').hide();
		$('#accordion_size > #collapse_size_.content, #accordion_size select#collapse_size_').show();

		var arr = [ 'XXS', 'XS', 'S', 'M', 'M / L', 'L', 'XL', 'XXL', 'XXXL', 'XXXXL' ];
		var arr1 = ["0","0.5","1","1.5","2","2.5","3","3.5","3/4","3 / 4","4","4.5","5","5.5","6","6.5","7","7.5","8","8.5","9","9.5","10","10.5","11","11.5","12","12.5","13","13.5","14","14.5","15",
		"15.5","16","16.5","17","17.5","18","18.5","19","19.5","20","20.5","21","21.5","22","22.5","23","23.5","24","24.5","25","25.5","26","26.5","27","27.5","28","28.5","29","29.5","30","30.5","31",
		"31.5","32","32.5","33","33.5","34","34.5","35","35.5","36","36.5","37","37.5","38","38.5","39","39.5","40","40.5","41","41.5","42","42.5","43","43.5","44","44.5","45","45.5","46","46.5","47",
		"47.5","48","48.5","48/50","48 / 50","48/51","48 / 51","49","49.5","50","50.5","51","51.5","52","52.5","53","53.5","54","54.5","55","55.5","56","56.5","57","57.5","58","58.5","59","59.5","60",
		"60.5","61","61.5","62","62.5","63","63.5","64","64.5","65","65.5","66","66.5","67","67.5","68","68.5","69","69.5","70","70.5","71","71.5","72","72.5","73","73.5","74","74.5","75","75.5","76",
		"76.5","77","77.5","78","78.5","79","79.5","80","80.5","81","81.5","82","82.5","83","83.5","84","84.5","85","85.5","86","86.5","87","87.5","88","88.5","89","89.5","90","90.5","91","91.5","92",
		"92.5","93","93.5","94","94.5","95","95.5","96","96.5","97","97.5","98","98.5","99","99.5","100","100.5","101","101.5","102","102.5","103","103.5","104","104.5","105","105.5","106","106.5","107",
		"107.5","108","108.5","109","109.5","110","110.5","111","111.5","112","112.5","113","113.5","114","114.5","115","115.5","116","116.5","117","117.5","118","118.5","119","119.5","120","120.5","121",
		"121.5","122","122.5","123","123.5","124","124.5","125","125.5","126","126.5","127","127.5","128","128.5","129","129.5","130"];
		jQuery.each( [arr, arr1], function( index, value ){
			jQuery.each(this, function(i, v) {
				if ( jQuery('.btn-size[data-name="'+v+'"]').length >= 1 ) {
					jQuery('.btn-size[data-name="'+v+'"]').addClass('or'+i+'');
				}
			});
		});
		$('#accordion_size').addClass('hidden');
		setTimeout(function(){
			var $firstSizeText = $('#accordion_size .title-footer').eq(0).find('label').text();
			$('#size-label option').eq(0).text($firstSizeText);
			
			$('#accordion_size .title-footer').each(function( index ) {
				if ( $(this).is(":visible") ) {
					var $country = $(this).data('target');
					$country = $country.replace("#collapse_size_", "");
					var i=0;
					$('#size-label option').each(function( index ) {
						//if ( $(this).val() == $country ) {
						if ( $(this).val() == $("#size-label").attr('data-size-info') ) {
							$(this).attr('hidden', false);
						}
						if ( i<=5 ) {
							$(this).attr('hidden', false);
						}
						i++;
					});
				}
			});
			$("#size-label").change(function() {
				var $countrySel = this.value;
				$('#accordion_size > .content, #accordion_size select.content-size-select').hide();
				if ( $countrySel == 'size') {
					$('#accordion_size > #collapse_size_.content, #accordion_size select#collapse_size_').show();
				} else {
					$('#accordion_size > .content.content-size-'+$countrySel+', #accordion_size select#collapse_size_'+$countrySel+'').show();
				}
			});
			var dsi ='--'+$("#size-label").attr('data-size-info');
			if(dsi != '--false'){
				//$("#size-label").val($("#size-label").attr('data-size-info'));
				//$("#size-label").trigger('change');
			}
			$('#size-label option[value="size"]').remove();
			$('#size-label option[value="INT"]').prop("selected", true);
			if($("#size-label option[value='"+$("#size-label").attr('data-size-info')+"']").length != 0){
				$("#size-label").val($("#size-label").attr('data-size-info'));
			}else{
				if(window.SIZE_INFO != ''){
					$('.link-size.js-link-size.link-underline').addClass('hidden');
				}
			}
			$('#size-label').change();
			$('#accordion_size').removeClass('hidden');
		},1000);
		
	}
	/* HP MAIN BANNER */
	/*if($('.banner-image').length != 0 ){
		var documentHeight = $( window ).height();
		var notifierHeight = 0;
		if($('#notification_carousel').length != 0){
			notifierHeight = $('#notification_carousel').innerHeight();			
		}
		function resizeBannerImage(){
			$('.banner-image').css('height',documentHeight - notifierHeight);
		}
		$( window ).resize(function() {
			documentHeight = $( window ).height();
			resizeBannerImage();
		});
		resizeBannerImage();
	}*/

	/*MAG FILTERS TAG */
	
	if( $('.tags-mag').length != 0 ){
		var tm = tags_mag || '';
		var filtersAdded = [];
		var filters= tm.split(',');
		for(var i in filters){
			if (filters[i] != ''){
				if( (filters[i] != '' && filtersAdded.length == 0) ||  $.inArray( filters[i], filtersAdded ) == -1){
					filtersAdded.push(filters[i]);
					$('.tags-mag #tagMobile').append('<div class="tag" data-zg-role="tag">'+filters[i]+'</a>');
					$('.tags-mag #tagDesktop').append('<div class="tag" data-zg-role="tag">'+filters[i]+'</a>');
				}
			}
		}
	}
	$('.tags-mag [data-zg-role="tag"]').on('click',function(){
		$('.tags-mag [data-zg-role="tag"]').removeClass('active');
		$(this).addClass('active');
		var tag= $(this).html();
		$('[data-zg-tag]').each(function(){
			$(this).removeClass('hidden');
			if(! $(this).attr('data-zg-tag').includes(tag) ){
				$(this).addClass('hidden');
			}
		});
	});
	
});

//*** QTY SELECTOR ***//

$('.btn-number').click(function(e){
    e.preventDefault();
    
    fieldName = $(this).attr('data-field');
    type      = $(this).attr('data-type');
    var input = $("input[name='"+fieldName+"']");
    var currentVal = parseInt(input.val());
    if (!isNaN(currentVal)) {
        if(type == 'minus') {
            
            if(currentVal > input.attr('min')) {
                input.val(currentVal - 1).change();
            } 
            if(parseInt(input.val()) == input.attr('min')) {
                $(this).attr('disabled', true);
            }

        } else if(type == 'plus') {

            if(currentVal < input.attr('max')) {
                input.val(currentVal + 1).change();
            }
            if(parseInt(input.val()) == input.attr('max')) {
                $(this).attr('disabled', true);
            }

        }
    } else {
        input.val(0);
    }
});
$('.input-number').focusin(function(){
   $(this).data('oldValue', $(this).val());
});
$('.input-number').change(function() {
    
    minValue =  parseInt($(this).attr('min'));
    maxValue =  parseInt($(this).attr('max'));
    valueCurrent = parseInt($(this).val());
    
    name = $(this).attr('name');
    if(valueCurrent >= minValue) {
        $(".btn-number[data-type='minus'][data-field='"+name+"']").removeAttr('disabled')
    } else {
        alert('Sorry, the minimum value was reached');
        $(this).val($(this).data('oldValue'));
    }
    if(valueCurrent <= maxValue) {
        $(".btn-number[data-type='plus'][data-field='"+name+"']").removeAttr('disabled')
    } else {
        alert('Sorry, the maximum value was reached');
        $(this).val($(this).data('oldValue'));
    }
});

$(".input-number").keydown(function (e) {
    // Allow: backspace, delete, tab, escape, enter and .
    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
         // Allow: Ctrl+A
        (e.keyCode == 65 && e.ctrlKey === true) || 
         // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
             // let it happen, don't do anything
             return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
    }
});



  $('.toggle-info').click(function(e){
	 	 e.preventDefault();
		 //$(this).toggleClass( 'showing' );
		 console.log("y")
		  if( $('.btn-card-info').hasClass('show') ){
			  console.log("testy")
			  $('.btn-plus-light').addClass('d-none');
			  $('.btn-less-light').addClass('d-block');
		  }
		  else{
			  $('.btn-plus-light').addClass('d-block');
			  $('.btn-less-light').addClass('d-none');
		  }
  });
$('.carousel_mag').on('slid.bs.carousel', function() {
	$(this).find('.pagination li.actived').removeClass('actived');
	$(this).find('.pagination li.active').next().addClass('actived');
});


$('#contactModal form').submit(function () {
	if($(this).valid()) {
		$('.form-fields').addClass('hidden');
		var productObj = $(this).parents('[data-product]').data('product');
		dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
			dataLayer.push({
			event: "richiesta_contatto_inviata",
			ecommerce: {
				items: [{
					item_id: productObj.id || '',
					item_name: productObj.name || '',
					affiliation: '',
					coupon: '',
					currency: productObj.price.currency,
					discount: 0,
					index: 0,
					item_brand: productObj.brand || BRAND,
					item_category: window.JS_TRANSLATIONS['category.name'],
					item_list_id: "",
					item_list_name: " ",
					item_variant: "",
					location_id: "",
					price: productObj.price.sell || '',
					quantity: 1 || ''
				}]
			}
        });
	}
});

$('[data-target="#contactModal"]').click(function(){
	var productObj = $(this).parents('[data-product]').data('product');
	dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
        event: "richiesta_contatto_prodotto",
        ecommerce: {
            items: [{
                item_id: productObj.id || '',
                item_name: productObj.name || '',
                affiliation: '',
                coupon: '',
                currency: productObj.price.currency,
                discount: 0,
                index: 0,
                item_brand: productObj.brand || BRAND,
                item_category: window.JS_TRANSLATIONS['category.name'],
                item_list_id: "",
                item_list_name: " ",
                item_variant: "",
                location_id: "",
                price: productObj.price.sell || '',
                quantity: 1 || ''
            }]
        }
        });
});


//GIFT CARD
$(document).ready(function() {  
    if( $("#voucher_amount").length){
        
        $('[data-zg-role="voucher_amount_custom"]').hide();
        
        $( document ).on( 'click','[data-zg-role="voucher_amount"]',function(e){
            //e.stopPropagation();
            e.preventDefault();
            var value = $(this).data('value');
            
            if (value == 'amount'){
                $('[data-zg-role="voucher_amount_custom"]').show();
                $('#voucher_amount').val('');
            }else{
                $('#voucher_amount').val(value);
                $('[data-zg-role="voucher_amount_custom"]').hide();
            }
            
            $('[data-zg-role="voucher_amount"]').removeClass('active');
            $(this).addClass('active');
            

        }); 
    }


    if( $('[data-zg-role="gift_card_type"]').length){
        $( document ).on( 'click','[data-zg-role="gift_card_type"]',function(e){
            e.preventDefault();
            $('[data-zg-role="gift_card_type"]').removeClass('active');
            $(this).addClass('active');
            var value = $(this).data('value');
            $('#voucher_custom_fields_gift_card_type').val(value);
            $('#voucher_custom_fields_gift_card_type').valid();
        });
    }
	/*OVERLAY*/
	/*
		$('.preloader-overlay').removeClass('load').addClass('hide');
	},300);*/

	
}); 
/*OVERLAY*/
/*$('.preloader-overlay').addClass('load');*/
	